import React from "react";

export default class PartsValue extends React.Component {
  render() {
    return (
      <div
        className={`parts-value ${
          this.props.className ? this.props.className : ""
        } ${
          this.props.value > 0 ? "green" : this.props.value < 0 ? "red" : ""
        }`}
      >
        <button
          onClick={() =>
            this.props.onUpdateValue(
              this.props.value - (this.props.title.includes("Pneu") ? 5 : 50)
            )
          }
        >
          -
        </button>
        <span className="m-auto">{this.props.value} €</span>
        <button
          onClick={() =>
            this.props.onUpdateValue(
              this.props.value + (this.props.title.includes("Pneu") ? 5 : 50)
            )
          }
        >
          +
        </button>
      </div>
    );
  }
}
